import { defineComponent, type PropType } from 'vue'

import type { ActionElements } from '@/javascript/interfaces'
import { type PaginationData, paginator } from '@/javascript/lib/paginator'
import { autoInitMdc } from '@/javascript/vuejs/helpers/autoInitMDC'
import { on } from '@/javascript/vuejs/helpers/emitEventHandler'

import { stringToNumber } from '../packages/utils'
import DataTableActions from './DataTableActions'
import DataTableCell from './DataTableCell'
import InputCheckbox from './InputCheckbox'

/* eslint-disable @typescript-eslint/no-unused-vars */
const emits = {
	'action': (action: string, id?: number | string | undefined): boolean => true,
	'selected': (ids: string[]): boolean => true,
}
/* eslint-disable */

export default defineComponent({
	name: 'DataTable',

	props: {
		...on(emits),

		additionalClass: { default: '', type: String },
		data: {
			required: true,
			type: Object as PropType<TableData>,
		},

		dataTest: { default: '', type: String },
		isStickyHeader: { default: false, type: Boolean },
		itemsPerPage: { default: 100, type: Number },
		message: { default: '', type: String },
		searchTerm: { default: '', type: String },
		showCheckboxes: { default: false, type: Boolean },
		showPagination: { default: false, type: Boolean },
		showPaginationRowsPerPage: { default: false, type: Boolean },
		showProgressIndicator: { default: false, type: Boolean },
		showSelectAll: { default: false, type: Boolean },
		sortable: { default: false, type: Boolean },
		tableLabel: { default: '', type: String },
		value: {
			required: false,
			type: Array as PropType<string[]>,
		},
	},

	emits,

	data(): Data {
		return {
			currentPage: 1,
			perPage: 25,
			selectedIds: [],
			sortByColumnId: this.data.columns[0].columnId,
			sortOrder: 'ascending',
		}
	},

	computed: {
		colspan(): number {
			return this.showCheckboxes ? this.data.columns.length + 1 : this.data.columns.length
		},

		filteredItems(): RowData[] {
			const term = this.searchTerm.toLowerCase()
			const items = !!this.searchTerm ?
				[...this.data.rows].filter(row => row.cells.some(cell => cell.title.text.toLowerCase().includes(term))) :
				[...this.data.rows]

			if (this.sortable) {
				const index = this.data.columns.findIndex(c => c.columnId === this.sortByColumnId) ?? 0

				items.sort((a, b) => {
					const columnA = a.cells[index].title.value ?? a.cells[index].title.text
					const columnB = b.cells[index].title.value ?? b.cells[index].title.text

					// Attempt to convert both cell values to numbers
					const numA = parseFloat(columnA.replace(/%/g, ''))
					const numB = parseFloat(columnB.replace(/%/g, ''))

					if (!isNaN(numA) && !isNaN(numB)) {
						// Both columnA and columnB are valid numbers, compare numerically
						return this.sortOrder === 'ascending' ? numA - numB : numB - numA
					} else {
						// At least one of columnA or columnB is not a number, compare as strings
						return this.sortOrder === 'ascending' ? columnA?.localeCompare(columnB) : columnB?.localeCompare(columnA)
					}

				})
			}

			return items
		},

		hasAllRowsSelected(): boolean {
			return this.selectedIds.length === this.rowIds.length
		},

		hasRows(): boolean {
			return this.data.rows.length > 0
		},

		isFirstPage(): boolean {
			return this.pagination.page === 1
		},

		isLastPage(): boolean {
			return this.pagination.page === this.pagination.totalPages
		},

		paginatedItems(): RowData[] {
			return this.pagination.data
		},

		pagination(): PaginationData<RowData> {
			return paginator(this.filteredItems, this.currentPage, this.perPage)
		},

		rowIds(): string[] {
			return this.data.rows.map(i => i.rowId)
		},
	},

	watch: {
		value() {
			if (this.value) {
				this.selectedIds = this.value
			}
		},
	},

	mounted() {
		autoInitMdc(this.$el)

		this.perPage = this.itemsPerPage || this.perPage
	},

	methods: {
		cellWidth(index?: number): string {
			return index ? `style="width: ${this.data.columns[index]?.width}"` : 'auto'
		},

		handleCheckbox(options: SelectedOptions): void {
			this.$nextTick(() => {
				if (options.all) {
					this.selectedIds = this.hasAllRowsSelected ? [] : this.rowIds
				} else {
					if (this.selectedIds.includes(options.id)) {
						this.selectedIds = this.selectedIds.filter(i => i !== options.id)
					} else {
						this.selectedIds.push(options.id)
					}
				}

				this.$emit('selected', this.selectedIds)
			})
		},

		handleClick(action: string | undefined, id: number | string | undefined): void {
			const rowAction = action ?? ''
			this.$emit('action', rowAction, id && typeof id === 'string' ? stringToNumber(id) : id)
		},

		isSelected(id: string): boolean {
			return this.selectedIds.includes(id)
		},

		onChangePage(direction: string, event?: Event): void {
			event?.preventDefault()
			switch (direction) {
				case 'first':
					this.currentPage = 1
					break

				case 'last':
					this.currentPage = this.pagination.totalPages
					break

				case 'next':
					this.currentPage = this.pagination.nextPage ?? this.pagination.totalPages
					break

				case 'previous':
					this.currentPage = this.pagination.previousPage ?? 1
					break
			}
		},

		sortBy(columnId: string, event: Event): void {
			event.preventDefault()
			if (this.sortByColumnId === columnId) {
				this.sortOrder = this.sortOrder === 'ascending' ? 'descending' : 'ascending'
			} else {
				this.sortByColumnId = columnId
				this.sortOrder = 'ascending'
			}
		},
	},

	render() {
		return (
			<div class={[
				'mdc-data-table',
				{ 'mdc-data-table--sticky-header': this.isStickyHeader },
				this.additionalClass,
			]}>
				<div class="mdc-data-table__table-container">
					<table
						aria-label={this.tableLabel}
						class="mdc-data-table__table"
						data-test={this.dataTest}
					>
						<thead>
							<tr class="mdc-data-table__header-row">
								{this.showCheckboxes &&
									<th
										aria-sort={this.sortable ? 'none' : undefined}
										class={[
											'mdc-data-table__header-cell mdc-data-table__header-cell--checkbox',
											{ 'mdc-data-table__header-cell--with-sort': this.sortable },
										]}
										role="columnheader"
										scope="col"
									>
										{this.showSelectAll &&
											<div class={[
												'mdc-checkbox mdc-data-table__header-row-checkbox',
												{ 'mdc-checkbox--selected': this.hasAllRowsSelected },
											]}>
												<input
													aria-label="Toggle all rows"
													class="mdc-checkbox__native-control"
													disabled={!this.hasRows}
													type="checkbox"
													onChange={(): void => this.handleCheckbox({ all: true, id: '' })}
												/>

												<div class="mdc-checkbox__background">
													<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
														<path
															class="mdc-checkbox__checkmark-path"
															d="M1.73,12.91 8.1,19.28 22.79,4.59"
															fill="none"
														/>
													</svg>

													<div class="mdc-checkbox__mixedmark" />
												</div>

												<div class="mdc-checkbox__ripple" />
											</div>
										}
									</th>
								}

								{this.data.columns && this.data.columns.map(column =>
									<th
										aria-sort={this.sortOrder}
										class={[
											'mdc-data-table__header-cell',
											{ 'mdc-data-table__header-cell--with-sort': this.sortable && column.isSortable },
										]}
										data-column-id={column.columnId}
										v-tooltip={column.tooltip}
										role="columnheader"
										scope="col"
										style={`width: ${column?.width}`}
										onClick={e => column.isSortable && column.columnId ? this.sortBy(column.columnId, e) : 'javascript:void(0)'}
									>
										{this.sortable ?
											<div class="mdc-data-table__header-cell-wrapper">
												<div class="mdc-data-table__header-cell-label" domProps-innerHTML={`${column.name}`} />

												{column.isSortable &&
													<button
														aria-describedby={column.columnId}
														aria-label={column.ariaLabel}
														class="mdc-icon-button material-icons mdc-data-table__sort-icon-button"
													>
														<i class="fa fa-arrow-stacked" aria-hidden="true" />
													</button>
												}

												<div
													aria-hidden="true"
													class="mdc-data-table__sort-status-label"
													id={column.columnId}
												/>
											</div>
											:
											<span domProps-innerHTML={`${column.name}`} />
										}
									</th>
								)}
							</tr>
						</thead>

						<tbody class="mdc-data-table__content">
							{this.paginatedItems.map(row =>
								<tr
									aria-selected={this.isSelected(row.rowId)}
									class={[
										'mdc-data-table__cell',
										{ 'mdc-data-table__row--selected': this.isSelected(row.rowId) },
									]}
									data-row-id={row.rowId}
								>
									{this.showCheckboxes &&
										<td class="mdc-data-table__cell mdc-data-table__cell--checkbox">
											<div
												class={[
													'mdc-checkbox mdc-data-table__row-checkbox',
													{ 'mdc-checkbox--selected': this.isSelected(row.rowId) },
												]}
												onClick={(): void => this.handleCheckbox({ all: false, id: row.rowId })}
											>
												<InputCheckbox
													dataTest="data-table-checkbox"
													id={`row-${row.rowId}`}
													value={this.isSelected(row.rowId)}
												/>
											</div>
										</td>
									}

									{row.cells?.map((cell, index) =>
										<DataTableCell
											cell={cell}
											index={index}
											width={this.data.columns[index]?.width}
										/>
									)}

									{row.actions &&
										<td class="mdc-data-table__cell mdc-data-table__cell_actions">
											{row.actions.length > 1 ?
												<DataTableActions
													actions={row.actions}
													id={row.rowId}
													onAction={(action: string, id: number): void => this.$emit('action', action, id)}
												/>

												:

												<a
													class={`custom-menu__control-button, ${row.actions[0].class}`}
													data-test={row.actions[0].dataTest}
													v-tooltip={row.actions[0].tooltip}
													href={row.actions[0].link ? row.actions[0].link : 'javascript:void(0)'}
													onClick={(): void => row.actions && row.actions[0].text ?
														this.handleClick(row.actions[0].text, row.rowId) : undefined}
												>
													<i aria-hidden="true" class={['fa', row.actions[0].icon]} />
												</a>
											}
										</td>
									}
								</tr>
							)}

							{!this.hasRows &&
								<tr>
									<td class="mdc-data-table__message" colspan={this.colspan}>
										{this.message}
									</td>
								</tr>
							}
						</tbody>
					</table>
				</div>

				{this.hasRows && this.showPagination &&
					<div class="mdc-data-table__pagination-navigation">
						{this.showPaginationRowsPerPage &&
							<div class="mdc-data-table__pagination-trailing">
								<div class="mdc-data-table__pagination-rows-per-page">
									<div class="mdc-data-table__pagination-rows-per-page-label">
										Rows per page
									</div>

									<div class="mdc-select mdc-select--outlined mdc-select--no-label mdc-data-table__pagination-rows-per-page-select">
										<div
											aria-haspopup="listbox"
											aria-labelledby="demo-pagination-select"
											class="mdc-select__anchor"
											role="button"
											tabindex={0}
										>
											<span class="mdc-select__selected-text-container">
												<span id="demo-pagination-select" class="mdc-select__selected-text">{this.perPage}</span>
											</span>

											<span class="mdc-select__dropdown-icon">
												<svg class="mdc-select__dropdown-icon-graphic" viewBox="7 10 10 5">
													<polygon
														class="mdc-select__dropdown-icon-inactive"
														stroke="none"
														fill-rule="evenodd"
														points="7 10 12 15 17 10"
													/>
													<polygon
														class="mdc-select__dropdown-icon-active"
														stroke="none"
														fill-rule="evenodd"
														points="7 15 12 10 17 15"
													/>
												</svg>
											</span>

											<span class="mdc-notched-outline mdc-notched-outline--notched">
												<span class="mdc-notched-outline__leading" />
												<span class="mdc-notched-outline__trailing" />
											</span>
										</div>
									</div>
								</div>
							</div>
						}

						<div class="mdc-data-table__pagination-total">
							Displaying {this.pagination.firstItem}
							{this.pagination.firstItem !== this.pagination.lastItem &&
								<span>&nbsp;&ndash; {this.pagination.lastItem}</span>} of {this.pagination.totalItems} in total
						</div>

						<button
							class={[
								'mdc-icon-button material-icons mdc-data-table__pagination-button',
								{ 'mdc-data-table__pagination-button-disabled': this.isFirstPage },
							]}
							data-first-page="true"
							disabled={this.isFirstPage}
							onClick={(): void => this.onChangePage('first')}
						>
							<div class="mdc-button__icon">
								<i class="fa fa-arrow-left-double" aria-hidden="true" />
							</div>
						</button>

						<button
							class={[
								'mdc-icon-button material-icons mdc-data-table__pagination-button',
								{ 'mdc-data-table__pagination-button-disabled': this.isFirstPage },
							]}
							data-prev-page="true"
							disabled={this.isFirstPage}
							onClick={e => this.onChangePage('previous', e)}
						>
							<div class="mdc-button__icon">
								<i class="fa fa-chevron-left" aria-hidden="true" />
							</div>
						</button>

						<button
							class={[
								'mdc-icon-button material-icons mdc-data-table__pagination-button',
								{ 'mdc-data-table__pagination-button-disabled': this.isLastPage },
							]}
							data-next-page="true"
							disabled={this.isLastPage}
							onClick={e => this.onChangePage('next', e)}
						>
							<div class="mdc-button__icon">
								<i class="fa fa-chevron-right" aria-hidden="true" />
							</div>
						</button>

						<button
							class={[
								'mdc-icon-button material-icons mdc-data-table__pagination-button',
								{ 'mdc-data-table__pagination-button-disabled': this.isLastPage },
							]}
							data-last-page="true"
							disabled={this.isLastPage}
							onClick={(): void => this.onChangePage('last')}
						>
							<div class="mdc-button__icon">
								<i class="fa fa-arrow-right-double" aria-hidden="true" />
							</div>
						</button>
					</div>
				}

				{this.showProgressIndicator &&
					<div class="mdc-data-table__progress-indicator">
						<div class="mdc-data-table__scrim" />

						<div
							aria-label="Data is being loaded..."
							class="mdc-linear-progress mdc-linear-progress--indeterminate mdc-data-table__linear-progress"
							role="progressbar"
						>
							<div class="mdc-linear-progress__buffer">
								<div class="mdc-linear-progress__buffer-bar" />
								<div class="mdc-linear-progress__buffer-dots" />
							</div>

							<div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
								<span class="mdc-linear-progress__bar-inner" />
							</div>

							<div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
								<span class="mdc-linear-progress__bar-inner" />
							</div>
						</div>
					</div>
				}
			</div>
		)
	},
})

interface Data {
	currentPage: number
	perPage: number
	selectedIds: string[]
	sortByColumnId: string | undefined
	sortOrder: 'ascending' | 'descending' | 'none' | 'other' | undefined
}

export interface Cell {
	details?: CellAttributes
	title: CellAttributes
}

export interface CellAttributes {
	class?: string
	dataTest?: string
	dataTestName?: string
	link?: string
	text: string
	value?: string
	tooltip?: string
}

export interface ColumnData {
	ariaLabel: string
	columnId?: string
	isSortable?: boolean
	name: number | string
	tooltip?: string
	width?: string
}

export interface RowData {
	actions?: ActionElements[]
	cells: Cell[]
	rowId: string
}

interface SelectedOptions {
	all: boolean
	id: string
}

export interface TableData {
	columns: ColumnData[]
	rows: RowData[]
}

export type SortOrder
	= ''
	| 'ascending'
	| 'descending'
	| 'none'
